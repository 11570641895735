import algoliaSearch from 'algoliasearch/lite'
import { AuthMode } from '@algolia/client-common'
import recommend from '@algolia/recommend'

export default {
  data: () => ({
    algoliaClient: null,
    algoliaRecommendClient: null
  }),
  computed: {
    indexName () {
      return this.getAlgoliaClient().initIndex(this.$store.getters['frontend/market'].name).indexName
    }
  },
  methods: {
    /**
     * Analytics client is a special algolia client that doesn't have a search function. Its because Algolia performs a search operation on mount, which cannot be disable any other way
     * @docs https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
     */
    getAnalyticsClient () {
      return {
        ...this.getAlgoliaClient(),
        search (requests) {
          return Promise.resolve({ results: requests.map(() => ({ hits: [] })) })
        },
      }
    },
    getAlgoliaClient () {
      if (process.client) {
        if (!this.algoliaClient) {
          this.algoliaClient = algoliaSearch(
            this.$config.algolia.appId,
            this.$config.algolia.searchApiKey, {
              authMode: AuthMode.WithinQueryParameters
            }
          )
        }
        return this.algoliaClient
      } else {
        return algoliaSearch(
          this.$config.algolia.appId,
          this.$config.algolia.searchApiKey
        )
      }
    },
    getAlgoliaRecommendClient (recommendType) {
      if (!process.client) {
        console.warn('Tried to getAlgoliaRecommendClient server-side, only client-side is supported')
        return
      }
      if (!this.algoliaRecommendClient) {
        this.algoliaRecommendClient = recommend(this.$config.algolia.appId, this.$config.algolia.searchApiKey)
      }

      return this.algoliaRecommendClient
    }
  }
}
