
import { mapMutations, mapActions, mapGetters } from 'vuex'
import algoliaInsights from '@/mixins/algoliaInsights'
import { ALGOLIA_KEY_SEARCH_RESULTS, ALGOLIA_KEY_MAIN, ALGOLIA_KEYS } from '@/utils/algoliaListKeys'

export default {
  name: 'AlgoliaResultsController',
  mixins: [algoliaInsights],
  props: {
    childProps: {
      type: Object,
      required: true
    },
    queryName: {
      type: String,
      required: true
    },
    childComponent: {
      type: String,
      default: 'ProductListAlgolia'
    },
    algoliaCentraCategory: {
      type: String,
      default: null
    },
    algoliaMerchandisingRule: {
      type: String,
      default: null
    },
    pageSize: {
      type: String,
      default: '20'
    },
    initialOffset: {
      type: Number,
      default: 0
    },
    productsFrom: {
      type: String,
      default: null
    },
    showProductList: {
      type: Boolean,
      default: true
    },
    algoliaRequestId: {
      type: String,
      default: ''
    },
    algoliaRequestIdFromStoryblok: {
      type: String,
      default: ''
    },
    isEmptySearch: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      localAlgoliaRequestId: '',
      algoliaResultsControllerIsValid: true,
      fetchingNewProducts: false,
    }
  },
  async fetch () {
    if (this.skipInitialFetch) {
      return
    }
    await this.fetch()
  },
  // Not rendering product lists server-side is fine SEO wise
  // for Miss Mary, the decision has been made by them.
  fetchOnServer: false,
  head () {
    return this.metaTags
  },
  computed: {
    ...mapGetters({
      sizechartStandard: 'algolia/sizechartStandard',
      productsVuex: 'algolia/products',
      totalProducts: 'algolia/totalProducts',
      viewedProducts: 'algolia/viewedProducts',
      facetsVuex: 'algolia/facets',
      initialFacets: 'algolia/initialFacets',
      facetFilters: 'algolia/facetFilters',
      queryIDVuex: 'algolia/queryID',
      isInAlgoliaLists: 'algolia/isInLists',
      initialFacetFilters: 'algolia/initialFacetFilters',
      currentConsumerVuex: 'algolia/currentConsumer',
      searchQuery: 'algolia/searchQuery',
      consumerWasDestroyed: 'algolia/consumerWasDestroyed',
      canonicalFacetFilters: 'algolia/canonicalFacetFilters',
      market: 'frontend/market',
      marketsList: 'frontend/marketsList',
      countriesSorted: 'frontend/countriesSorted',
    }),
    shouldRenderChildComponent () {
      return (
        this.isInAlgoliaLists(this.requestId) &&
        typeof this.products !== 'undefined' &&
        typeof this.facets !== 'undefined' &&
        typeof this.queryID !== 'undefined'
      )
    },
    requestId () {
      return this.localAlgoliaRequestId || this.generateRequestId()
    },
    skipInitialFetch () {
      if (this.requestId === ALGOLIA_KEY_SEARCH_RESULTS) {
        return true
      }
      if (this.consumerWasDestroyed(this.requestId)) {
        return false
      }
      return typeof this.queryID !== 'undefined'
    },
    currentConsumer () {
      return this.currentConsumerVuex(this.requestId)
    },
    shuffledProducts () {
      const shuffledProducts = this.products
      for (let i = shuffledProducts.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[shuffledProducts[i], shuffledProducts[j]] = [shuffledProducts[j], shuffledProducts[i]]
      }

      return shuffledProducts
    },
    productList () {
      return this.shouldShuffleProducts ? this.shuffledProducts : this.products
    },
    shouldShuffleProducts () {
      return this.$route.path.includes('/limited-edition') || this.$route.path.includes('/swimwear-news')
    },
    metaTags () {
      if (this.localAlgoliaRequestId !== ALGOLIA_KEY_MAIN) {
        return {}
      }
      if (!this.canonicalFacetFilters) {
        return {}
      }
      return {
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: 'https://www.' +
              this.market.name +
              this.$route.path +
              this.canonicalFacetFilters,
          }
        ]
      }
    },
    facets () {
      return this.facetsVuex(this.requestId)
    },
    products () {
      return this.productsVuex(this.requestId)
    },
    queryID () {
      return this.queryIDVuex(this.requestId)
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler () {
        if (this.isEmptySearch) {
          await this.handleQueryParams({ id: this.requestId, skipRequest: true })
        } else {
          await this.handleQueryParams({ id: this.requestId })
        }
        if (this.localAlgoliaRequestId === ALGOLIA_KEY_MAIN) {
          this.calculateCanonicalFacetFilters()
        }
      },
    },
    async algoliaCentraCategory () {
      await this.fetch({ refetchFacets: true })
    }
  },
  created () {
    if (this.algoliaRequestIdFromStoryblok && ALGOLIA_KEYS.includes(this.algoliaRequestIdFromStoryblok)) {
      console.error(`Algolia Request ID cannot be any of ${JSON.stringify(ALGOLIA_KEYS)}, but got ${this.algoliaRequestIdFromStoryblok}`)
      this.algoliaResultsControllerIsValid = false
    }
    if (this.algoliaRequestIdFromStoryblok && this.childProps?.displayFilter) {
      console.warn('Algolia Request ID is unused since the display filter option enabled. Falling back to main Algolia Request ID.')
    }
    if (this.currentConsumer !== this.uid && this.currentConsumer !== false) {
      console.error(`Algolia Request ID "${this.requestId}" is already in use on this page. The second product list will not be displayed.`)
      this.algoliaResultsControllerIsValid = false
    }

    if (!this.algoliaResultsControllerIsValid) {
      return
    }

    for (const key of [...ALGOLIA_KEYS, this.requestId]) {
      if (!this.isInAlgoliaLists(key)) {
        this.createList(key)
      }
    }

    this.setAlgoliaListSettings({
      id: this.requestId,
      productsFrom: this.productsFrom,
      algoliaMerchandisingRule: this.algoliaMerchandisingRule,
      limit: this.pageSize,
      pageSize: this.pageSize,
      offset: this.initialOffset,
      currentConsumer: this.uid,
    })
  },

  beforeDestroy () {
    this.setAlgoliaListSettings({ id: this.requestId, currentConsumer: undefined })
    this.emptyList(this.requestId)
  },
  methods: {
    ...mapMutations({
      resetInitialFacets: 'algolia/resetInitialFacets',
      setInitialFacetFilters: 'algolia/initialFacetFilters',
      setFacetFilters: 'algolia/facetFilters',
      createList: 'algolia/createList',
      emptyList: 'algolia/emptyList',
      setAlgoliaListSettings: 'algolia/listSettings',
    }),
    ...mapActions({
      queryAlgolia: 'algolia/executeQuery',
      queryAlgoliaForFacets: 'algolia/executeQueryForFacets',
      isAlgoliaQueryNeeded: 'algolia/isQueryNeeded',
      loadMore: 'algolia/loadMore',
      handleQueryParams: 'algolia/handleQueryParams',
      queryToFacetFilters: 'algolia/queryToFacetFilters',
      calculateCanonicalFacetFilters: 'algolia/calculateCanonicalFacetFilters',
    }),
    async fetch ({ refetchFacets = false } = {}) {
      let initialFacetFilters = []
      if (this.algoliaCentraCategory) {
        initialFacetFilters = [...initialFacetFilters, {
          facetName: 'categories.uri',
          value: this.algoliaCentraCategory
        }]
      }
      this.setInitialFacetFilters({ id: this.requestId, value: initialFacetFilters })
      this.setFacetFilters({ id: this.requestId, value: initialFacetFilters })

      let [queryIsNeeded] = await this.isAlgoliaQueryNeeded(this.requestId)
      if (!queryIsNeeded) {
        return
      }

      let hasFetchedFacets = false
      // Here we only need to fetch facets if they are undefined
      // This is dumb, but its needed because we need facets to parse color in query params
      if (refetchFacets || typeof this.facets === 'undefined') {
        hasFetchedFacets = true
        this.fetchingNewProducts = true
        await this.queryAlgoliaForFacets(this.requestId)
      }

      await this.handleQueryParams({ id: this.requestId, skipRequest: true })
      if (this.searchQuery(this.requestId)) {
        // Reset initial facets, otherwise unexpected behavior in search view
        this.resetInitialFacets(this.requestId)
      }

      // Check again if Algolia query is needed now that we have parsed uri query params
      [queryIsNeeded] = await this.isAlgoliaQueryNeeded(this.requestId)
      if (!queryIsNeeded) {
        return
      }

      this.fetchingNewProducts = true
      // Fetch facets if we didn't do it above
      if (!hasFetchedFacets) {
        await this.queryAlgoliaForFacets(this.requestId)
      }
      await this.queryAlgolia(this.requestId)
      this.fetchingNewProducts = false

      this.calculateCanonicalFacetFilters()
    },
    generateRequestId () {
      // Only one product list can have filtering, so this has to be 'main'
      if (this.childProps?.displayFilter) {
        this.localAlgoliaRequestId = ALGOLIA_KEY_MAIN
        return this.localAlgoliaRequestId
      }

      let res = this.algoliaRequestId ?? ''
      if (!res) {
        res = this.algoliaRequestIdFromStoryblok ?? ''
      }
      if (!res) {
        res = ALGOLIA_KEY_MAIN
      }
      this.localAlgoliaRequestId = res
      return res
    },
  }
}
